.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
  margin-bottom: 10px;
}

.inputWrapper > label {
  font-weight: bold;
  margin-bottom: 5px;
}

.inputWrapper > span {
  font-size: 12px;
}

input[type="submit"]:not([hidden]) {
  all: unset;
  border: 1px solid #2d2d2d;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}
input[type="submit"]:not([hidden]):active,
input[type="submit"]:not([hidden]):focus {
  background-color: #2d2d2d;
  color: #ffffff;
}

.buttons {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 16px;
}

input[type="submit"][disabled],
input[type="submit"]:disabled {
  color: #969696;
  background-color: #eaeaea;
  border-color: #eaeaea;
  cursor: not-allowed;
}

.overlay {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay > span {
  color: white;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 2;
}
